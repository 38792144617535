import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SecondaryButtonSmall, OrSeparater, LogInCard, LogInCardTitle, ErrorMessage, NoticeMessage } from "../components/CommonComponents"
import { UserContext } from "../../UserContext";


import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useSignMessage, useAccount } from "wagmi";
import LockIcon from '../../../../../assets/images/lock_icon.svg';

// Api Calls
import RegistrationAPI from "../api/api_calls/RegistrationAPI";
import sessionAPI from "../api/api_calls/sessionAPI";





function SignInWallet(props) {

    const [error, setError] = useState([]);
    const [notice, setNotice] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser, refreshUser } = useContext(UserContext);


    const [isConnected, setIsConnected] = useState(false);
    const { address } = useAccount();
    const [messageToSign, setMessageToSign] = useState("Despark is having a server error. Signing this is harmless, but won't log you in. Please try again later.");
    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();
    // const [signMessage, { data, isError, isLoading, isSuccess }] = useSignMessage();
    // const isSuccess = false;



    // --------------------------------------------
    // --------------------------------------------
    // --------------UseEffects------------------
    // --------------------------------------------
    // --------------------------------------------



    // Update the notice if we receive one through navigation
    useEffect(() => {
        if (location.state) {
            const tempNotice = location.state.notice || "";
            setNotice(tempNotice)
        }
    }, []);

    // Once they've connected their wallet, start signature process.
    useEffect(() => {
        setIsConnected(!!address);
        setError("");

        // Okay, so we're connected.
        // Things to do:
        // 1. Check if the wallet is  registered
        // 2. If not, then we direct them to the signup page
        // 3. If so, then we get the nonce, ask them to sign, and then validate it to log them in.
        // 4. If they're not connected, then we keep showing the normal page here.

        const GetNonceAndPromptSignature = async (userWallet) => {
            const result = await RegistrationAPI.getNonce(userWallet, navigate);

            if (result.success) {
                // Update the message to have the correct nonce.
                setMessageToSign(result.signature_message)
            } else {
                // Display an error message
                setError("Something went wrong - our servers can't currently handle wallet signatures. Please try again later or sign up using email. ")
            }
        }


        if (!!address) {


            const CheckWalletExists = async (address) => {
                const result = await RegistrationAPI.checkWalletExists(address, navigate);

                if (result.success && result.exists) {
                    // Wallet exists. Prompt a signature, show a display on the page.
                    GetNonceAndPromptSignature(address)

                } else if (result.success && !result.exists) {
                    // Wallet doesn't have an account - Navigate them to the signup page 
                    navigate("/signup", { state: { notice: "This wallet does not have an account. Please sign up." } });
                } else {
                    // Display an error message
                    setError("Something went wrong. Please try again later. ")
                }
            }

            CheckWalletExists(address)
        }

    }, [address]);

    // Once they've connected their wallet, start signature process.
    useEffect(() => {
        if (messageToSign.startsWith("Logging")) {
            signMessage({ message: messageToSign });
        }

    }, [messageToSign]);

    // Once the signature is returned, we need to send it to the backend.
    useEffect(() => {

        // Function to call the async signup function.
        const SignInUser = async () => {
            const log_hash = {
                user_agent: navigator.userAgent,
                window_outer_width: window.outerWidth,
                window_outer_height: window.outerHeight,
                window_inner_width: window.innerWidth,
                window_inner_height: window.innerHeight,
                screen_width: window.screen.width,
                screen_height: window.screen.height
            }
            const result = await sessionAPI.createFromWallet(address, data, log_hash, navigate);

            // TODO:
            // 1. IF the signature isn't valid, do something. 
            // 2. Otherwise, handle the same issues as normal.
            // 3. Otherwise, success!

            if (result.success) {
                refreshUser();

                navigate("/dashboard")
                // We use the javascript here to force it reload.
                // We pass through a rails route to force cSRF token to reload.
                // window.location.href = "/post_sign_in";

            } else {
                // Some errors want a notice instead of an error.
                if (result.reason == "unconfirmed") {
                    setNotice(data.failureReason);
                    setError("You have not confirmed your email. Please check your email for a confirmation link.")
                } else {
                    // sets the error message to display to user
                    setError("Something went wrong. Please contact info@despark.io if this persists.");
                }
            }
        }

        // If the signature is successful, submit the signature to the backend.
        if (isSuccess) {
            SignInUser()
        }

    }, [isSuccess]);


    // --------------------------------------------
    // --------------------------------------------
    // --------------REturn Value------------------
    // --------------------------------------------
    // --------------------------------------------

    return (
        <div className="container-fluid bg-black-color">
            <div className='row d-flex justify-content-center align-items-center h-100'>
                <ErrorMessage errors={error} />
                <NoticeMessage notices={notice} />
                <div className="bg-black-color full-page-background">
                </div>
                <div className="col12">
                    <div className=' mw-440  my-80px mx-auto'>  {/* This sets the width and margins */}
                        <LogInCard>
                            <LogInCardTitle title="Wallet Login" />
                            <div className="card-title-note text-center">
                                Sign your wallet to log in
                            </div>
                            <div className="mt-2"></div>
                            <div className="mt-5 mb-32 mx-2 ">
                                <img src={LockIcon} />
                            </div>
                            <div className="card-body-2 px-2">
                                <div className="d-flex flex-column justify-content-center align-items-center my-32">
                                    <ConnectButton label="Connect Wallet" showBalance={false} chainStatus="none" />
                                    {address && <div className="text-center text-gray  mt-2">Waiting on wallet signature...</div>}
                                </div>
                                <div className="bg-transparent-color">
                                    <OrSeparater />
                                    <div className="d-flex justify-content-center mt-32">
                                        <SecondaryButtonSmall text="Email Login" buttonType="submit" onClick={() => navigate("/signin")} />
                                    </div>
                                </div>
                            </div>
                        </LogInCard>
                        <div className="flex-column align-items-center pcx-30 my-32px w-100">
                            <div className="sign-up-tertiary">Don't have an account? <Link className="secondary-link" to={{ pathname: "/signup/wallet" }}>Sign Up</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



export default SignInWallet