import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import { Survey, Model, StylesManager } from "survey-react";
import "../../../../../../../node_modules/survey-react/defaultV2.css";
import "survey-react/defaultV2.css";
import { UserContext } from "../../../UserContext";


// API Calls
import SurveyAPI from "../../api/api_calls/SurveyAPI";




function SurveyContent(props) {
    const { user, setUser, refreshUser } = useContext(UserContext);

    const alert = useAlert();
    const navigate = useNavigate();
    const [survey, setSurvey] = useState(null);
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [surveyTitle, setSurveyTitle] = useState("Loading...");
    const [missionTitle, setMissionTitle] = useState("Loading...");
    const [missionReward, setMissionReward] = useState("Loading...");
    const [failedLoading, setFailedLoading] = useState(false);

    const [surveyLoaded, setSurveyLoaded] = useState(false);
    const exampleProp = useOutletContext();
    let params = useParams()
    const surveyId = params.surveyId;
    StylesManager.applyTheme("defaultV2");


    // On complete, save the survey results
    const onSurveyComplete = useCallback(async (sender) => {
        // This is for the admin survey display. 
        // Stops from actually submitting the results. 
        if (props.adminResponse) {
            alert.show("Admin does not submit surveys", { type: "info" });
        } else {

            const isScreener = false
            const response = await SurveyAPI.create_response(sender.data, surveyId, isScreener, navigate)

            // This is all we actually do - there's no more steps for the user. 
            // They just see a message and a "back to missions" button. 
            setSurveyComplete(true);

            if (response.success) {
                alert.show("Survey submitted!", { type: 'success' })
                refreshUser();
                navigate("/missions")
            } else {
                alert.show("Unable to save survey. Please contact info@despark.io", { type: 'error' })
            }
        }
    }, []);

    // Load the survey
    useEffect(() => {
        //Grab information on this user
        const GetSurvey = async () => {
            const response = await SurveyAPI.show(surveyId);

            if (response.success) {
                // Once it loads, then run through and set all the survey stuff
                const tempSurvey = new Model(await response.survey);
                tempSurvey.onComplete.add(onSurveyComplete);
                setSurvey(tempSurvey);
                setSurveyLoaded(true);
                setSurveyTitle(response.title);
                setMissionTitle(response.mission_title);
                setMissionReward(response.mission_reward);
            } else {
                setFailedLoading(true);
            }

        }

        GetSurvey();
    }, []);


    return (
        <div className="row mx-4 text-white">
            <div className="surveytitle px-4 py-4">
                {surveyLoaded ? missionTitle : <div>Loading...</div>}
                <div className="normal-font my-3">
                    Complete the survey below. Upon completion, the reward of ${Math.round(missionReward)} will be added to your balance.
                </div>
            </div>
            <SwitchCompleteAndFailedSurvey failedLoading={failedLoading} surveyComplete={surveyComplete} surveyLoaded={surveyLoaded} survey={survey} />
        </div>
    )

}

function SwitchCompleteAndFailedSurvey(props) {
    if (props.failedLoading) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Survey not found.
                </div>
                <Link to={{ pathname: "/missions" }}
                    className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    Back to Missions
                </Link>
            </div>
        )
    } else if (props.surveyComplete) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    You've completed the mission. Your balance will be updated shortly.
                </div>
                <Link to={{ pathname: "/missions" }}
                    className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    Back to Missions
                </Link>
            </div>
        )
    } else {
        return (
            <div id="surveyElement">
                {props.surveyLoaded ? <Survey model={props.survey} /> : <div>Loading...</div>}
            </div>
        )
    }
}

export default SurveyContent