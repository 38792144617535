import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LogInCard, ErrorMessage, NoticeMessage } from "../components/CommonComponents"
import EmailIcon from '../../../../../assets/images/email_icon.svg';


function EmailValidation(props) {

    const [error, setError] = useState("");
    const [notice, setNotice] = useState("");

    document.body.style = 'background: #8248fc;';

    return (
        <div className="container-fluid bg-black-color">
            <div className="bg-black-color full-page-background">
            </div>
            <div className='row d-flex justify-content-center align-items-center h-100'>
                <ErrorMessage errors={error} />
                <NoticeMessage notices={notice} />
                <div className="col12">
                    <div className=' mw-440  my-80px mx-auto'>  {/* This sets the width and margins */}
                        <LogInCard>
                            <div className="standard-semibold-text text-center">
                                <div className="m-2">
                                    <img src={EmailIcon} />

                                </div>
                                <div className="mt-5">
                                    A link has been sent to your email. Click the link to confirm your account.
                                </div>
                                <div className="mt-32">
                                    Can't find the email? Check your spam or junk folder.
                                    {/* Didn’t receive an email? <a className="secondary-link"> Resend</a> */}
                                </div>
                            </div>
                        </LogInCard>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default EmailValidation