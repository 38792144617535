
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PrimaryButton, TurnBackgroundBlackComponent } from "../preauth_portal/components/CommonComponents"

// API Calls
import MeetingsAPI from "../preauth_portal/api/api_calls/MeetingsAPI";
import CenteredLoadingIcon from "../main_portal/components/CenteredLoadingIcon";

function JoinMeetingContent(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [missionName, setMissionName] = useState("");
    const [startTime, setStartTime] = useState(new Date());
    const [formattedStartTime, setFormattedStartTime] = useState("");
    const [endTime, setEndTime] = useState(new Date());
    const [timeUntilString, setTimeUntilString] = useState("");
    const [meetingLink, setMeetingLink] = useState("");
    const [changeLink, setChangeLink] = useState("/");
    const [meetingLoaded, setMeetingLoaded] = useState(false);
    const [meetingExists, setMeetingExists] = useState(false);
    const [inProgressMeeting, setInProgressMeeting] = useState(false);

    const slug = location.pathname.split("/").pop();

    useEffect(() => {
        async function GetMeeting(width, height, slug) {
            const data = await MeetingsAPI.show_user({ device_width: width, device_height: height, meeting_slug: slug, handleRedirect: navigate });

            if (data.success) {
                setMissionName(data.mission_name);
                setStartTime(data.start_time);
                setFormattedStartTime(data.formatted_start_time);
                setEndTime(data.end_time);
                setTimeUntilString(data.time_until_string);
                setMeetingLink(data.meeting_link);
                setChangeLink(data.change_link);
                setInProgressMeeting(data.in_progress);
                setMeetingExists(true);
                setMeetingLoaded(true);
            } else {
                setMissionName("Mission Not Found");
                setMeetingExists(false);
                setMeetingLoaded(true);
            }
        }

        const width = window.screen.width;
        const height = window.screen.height;

        GetMeeting(width, height, slug);
    }, []);

    useEffect(() => {
        const meetingTime = new Date(startTime).getTime();
        const now = Date.now();
        const tenMinutesBeforeMeeting = meetingTime - 10 * 60 * 1000;
        const timeUntilReload = tenMinutesBeforeMeeting - now;

        if (timeUntilReload > 0) {
            const timeoutId = setTimeout(() => {
                window.location.reload();
            }, timeUntilReload);

            return () => clearTimeout(timeoutId);
        }
    }, [startTime]);

    function SessionCard() {
        const eventTime = new Date(startTime);
        const currentTime = new Date();
        const differenceInMilliseconds = eventTime - currentTime;
        const withinTenMinutes = differenceInMilliseconds > 0 && differenceInMilliseconds <= 10 * 60 * 1000;

        if (!meetingLoaded) {
            return <LoadingSession />;
        } else if (meetingExists) {
            if (inProgressMeeting) {
                return <InProgressSession missionName={missionName} formattedStartTime={formattedStartTime} timeUntilString={timeUntilString} meetingLink={meetingLink} changeLink={changeLink} />;
            } else if (timeUntilString === null) {
                return <PastSession missionName={missionName} formattedStartTime={formattedStartTime} />;
            } else if (withinTenMinutes) {
                return <ImminentSession missionName={missionName} formattedStartTime={formattedStartTime} timeUntilString={timeUntilString} meetingLink={meetingLink} changeLink={changeLink} />;
            } else {
                return <UpcomingSession missionName={missionName} formattedStartTime={formattedStartTime} timeUntilString={timeUntilString} changeLink={changeLink} />;
            }
        } else {
            return <NotFoundSession />;
        }
    }

    return (
        <div className="container-fluid bg-black-color">
            <div className='row d-flex justify-content-center align-items-center h-100'>
                <TurnBackgroundBlackComponent />
                <SessionCard />
            </div>
        </div>
    );
}

function MeetingCard({ preTitle, title, date, timeSummaryStart, timeSummaryEnd, bodyText, buttonText, buttonAction, changeLink }) {
    return (
        <div className="meeting-join-container">
            <div className="meeting-join-card">
                <div className="meeting-join-card-header">
                    <div className="meeting-join-card-header-title-section">
                        <div className="meeting-join-card-header-pre-title">
                            {preTitle}
                        </div>
                        <div className="meeting-join-card-header-title">
                            {title}
                        </div>
                    </div>
                    <div className="meeting-join-card-timing-section">
                        <div className="meeting-join-card-date">
                            {date}
                        </div>
                        <div className="meeting-join-card-time-summary">
                            {timeSummaryStart} <span className="meeting-join-card-time-highlight">{timeSummaryEnd} </span>
                        </div>
                    </div>
                </div>
                <div className="meeting-join-card-body">
                    {buttonText && buttonAction && (
                        <div className="button-holder">
                            <PrimaryButton text={buttonText} onClick={buttonAction} />
                        </div>
                    )}
                    <div className="meeting-join-card-body-text">
                        {bodyText}
                    </div>
                </div>
            </div>
            {changeLink && (
                <div className="meeting-tertiary-link-wrapper">
                    Need to <Link to={changeLink} className="meeting-tertiary-link">make a change?</Link>
                </div>
            )}
        </div>
    );
}

function UpcomingSession(props) {
    return (
        <MeetingCard
            preTitle="Scheduled Mission"
            title={props.missionName}
            date={props.formattedStartTime}
            timeSummaryStart={`Starting in `}
            timeSummaryEnd={props.timeUntilString}
            bodyText="This session opens 10 minutes before the scheduled start time. Please check back then."
            changeLink={props.changeLink}
        />
    );
}

function ImminentSession(props) {
    return (
        <MeetingCard
            preTitle="Scheduled Mission"
            title={props.missionName}
            date={props.formattedStartTime}
            timeSummaryStart={`Starting in `}
            timeSummaryEnd={props.timeUntilString}
            bodyText="Click the button to join your scheduled mission if you are not automatically redirected."
            buttonText="Join session"
            buttonAction={() => window.open(props.meetingLink, '_blank')}
        />
    );
}

function InProgressSession(props) {
    return (
        <MeetingCard
            preTitle="Scheduled Mission"
            title={props.missionName}
            date={props.formattedStartTime}
            timeSummaryStart="This meeting "
            timeSummaryEnd="has started"
            bodyText="Click the button to join your scheduled mission if you are not automatically redirected."
            buttonText="Join session"
            buttonAction={() => window.open(props.meetingLink, '_blank')
            }
        />
    );
}

function PastSession(props) {
    return (
        <MeetingCard
            preTitle="Scheduled Mission"
            title={props.missionName}
            date={props.formattedStartTime}
            timeSummaryStart="This session is in the past."
        />
    );
}

function NotFoundSession() {
    return (
        <MeetingCard
            preTitle="Scheduled Mission"
            title="Not Found"
            date="N/A"
            timeSummaryStart="This session does not exist."
        />
    );
}

function LoadingSession() {
    return (
        <div className="meeting-join-container">
            <div className="meeting-join-card">
                <CenteredLoadingIcon />
            </div>
        </div>
    );
}

export default JoinMeetingContent;