import { apiConfig } from '../../../../researcher/preauth_portal/api/apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../../../../researcher/preauth_portal/api/apiUtils';

export default {
    show_user: async ({ device_width, device_height, meeting_slug, handleRedirect = null }) => {
        const url = apiConfig.apiUrl + 'api/meetings/u/' + meeting_slug;


        const body = JSON.stringify({
            device: {
                width: device_width,
                height: device_height
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    show_researcher: async ({ device_width, device_height, meeting_slug, handleRedirect = null }) => {
        const url = apiConfig.apiUrl + "api/meetings/r/meeting_slug";


        const body = JSON.stringify({
            device: {
                width: device_width,
                height: device_height
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },





}