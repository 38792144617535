import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import FullPageBackground from "./standard_components/FullPageBackground";
import InfoIcon from '../../../../assets/images/mission_detail_icons/blue_info_icon.svg';
import ClockIcon from '../../../../assets/images/mission_detail_icons/blue_clock_icon.svg';
import DollarIcon from '../../../../assets/images/mission_detail_icons/blue_dollar_icon.svg';
import { MissionDetailsIconComponent, MissionDetailsIconRow, MissionDetailsHeader, MissionDetailsTitle } from "./overview_components/CommonOverviewComponents";
import { Route, Routes } from "react-router-dom";
import { useAccount } from "wagmi";
import LoadingIcon from "./standard_components/LoadingIcon";


// Pages
import PMOverview from "./pages/landing_page/PMOverview";
import PublicScreener from "./pages/screener/PublicScreener";
import PublicSurvey from "./pages/survey/PublicSurvey";
import PublicCashOut from "./pages/complete/PublicCashOut";
import PublicComplete from "./pages/complete/PublicComplete";
import PublicSchedule from "./pages/schedule/PublicSchedule";
import PublicRejected from "./pages/rejected/PublicRejected";


// API Calls
import SessionAPI from "./api/api_calls/SessionAPI";
import PublicMissionAPI from "./api/api_calls/PublicMissionAPI";




function PublicMission(props) {
    const [missionName, setMissionName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [hasScreener, setHasScreener] = useState(false)
    const [timeEstimate, setTimeEstimate] = useState("")
    const [rewardAmount, setRewardAmount] = useState("")
    const [missionType, setMissionType] = useState("")
    const [missionStatus, setMissionStatus] = useState("")
    const [nextPath, setNextPath] = useState("")
    const [missionDescription, setMissionDescription] = useState("")
    const [walletRequirements, setWalletRequirements] = useState([])
    const { address, isConnecting, isConnected, isDisconnected } = useAccount();

    const [missionLoading, setMissionLoading] = useState(true)
    const [checkPageLoading, setCheckPageLoading] = useState(true)


    let params = useParams()
    const missionId = params.missionId;
    const navigate = useNavigate();


    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------

    useEffect(() => {

        // Get the data for hte mission that will persist throughout.
        async function GetMissionData() {
            const missionData = await PublicMissionAPI.getMission(missionId, navigate)
            if (missionData.success) {
                setMissionName(missionData.mission_name)
                setCompanyName(missionData.company_name)
                setHasScreener(missionData.has_screener)
                setTimeEstimate(missionData.time_estimate)
                setRewardAmount(missionData.reward_amount)
                setMissionType(missionData.mission_type)
                setMissionStatus(missionData.mission_status)
                setMissionDescription(missionData.mission_description)
                setNextPath(missionData.next_path)
                setWalletRequirements(missionData.wallet_requirements)

                setMissionLoading(false)
            }
        }

        // Single call to check the initial page the user has come to. 
        // If the user 
        async function CheckInitialPage() {
            const pageCheckResult = await SessionAPI.checkInitialPage(window.location.href, missionId, navigate)
            setCheckPageLoading(false)
            // Redirects are handled within the API call itself.
        }

        GetMissionData()
        CheckInitialPage()
    }, [])

    useEffect(() => {

        async function DestroySession() {
            const sessionDestroyResult = await SessionAPI.destroySession(missionId, navigate)

            if (sessionDestroyResult.success) {
                // Redirect to the next page
                navigate(sessionDestroyResult.next_path)
                // window.location.assign(sessionDestroyResult.full_path)
            }
        }

        // Validate that the user is connected to the wallet.
        // First check whether they are in the process of connecting, then check if they are connected.
        if (!isConnecting && !address) {
            // Log them out
            // Tell Rails to reset the session. 
            // Redirect them to this page.
            DestroySession()

        }

    }, [isConnecting, isConnected, isDisconnected, address])


    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------


    return (
        <>
            <FullPageBackground />
            <FullPageContainer>
                <SectionTitle title="Community Feedback" />
                <MissionDetailsCard>
                    {checkPageLoading || missionLoading ? <LoadingIcon /> :
                        <>
                            <MissionDetailsHeader>
                                <MissionDetailsTitle title={missionName} />
                                <MissionDetailsIconRow>
                                    {hasScreener ? <MissionDetailsIconComponent icon={InfoIcon} text="Screener Required" /> : <></>}
                                    {timeEstimate != "null" ? <MissionDetailsIconComponent icon={ClockIcon} text={timeEstimate + " minutes"} /> : <></>}
                                    {rewardAmount > 0 ? <MissionDetailsIconComponent icon={DollarIcon} text={"$" + rewardAmount + " USDC Reward"} /> : <></>}
                                </MissionDetailsIconRow>
                            </MissionDetailsHeader>
                            <Routes>
                                <Route path="/" element={<PMOverview description={missionDescription} companyName={companyName} walletRequirements={walletRequirements} setWalletRequirements={setWalletRequirements} nextPath={nextPath} missionStatus={missionStatus} />} />
                                <Route path="/screener" element={<PublicScreener />} />
                                <Route path="/schedule" element={<PublicSchedule timeEstimate={timeEstimate} />} />
                                <Route path="/survey" element={<PublicSurvey />} />
                                {/* <Route path="/cash_out" element={<PublicCashOut />} />  */}
                                <Route path="/complete" element={<PublicComplete hasReward={rewardAmount > 0} />} />
                                <Route path="/rejected" element={<PublicRejected />} />

                            </Routes>
                        </>
                    }
                </MissionDetailsCard>
            </FullPageContainer>
        </>
    )

}

function SectionTitle(props) {
    return (
        <div className="page-title-text px-4 px-sm-0 py-4">
            {props.title}
        </div>
    )
}

function FullPageContainer(props) {
    return (
        <div className="container-xl container-not-xxl bg-black-color px-1 px-sm-5 px-xl-0 pb-3">
            {props.children}
        </div>
    )
}

function MissionDetailsCard(props) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="mission-details-card d-flex flex-column">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default PublicMission;