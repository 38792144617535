import React from "react";
import TooltipIcon from '../../../../../assets/images/info_icon.svg';


function MissionDetailsHeader(props) {
    return (
        <div className="mission-details-header d-flex flex-column">
            {props.children}
        </div>
    )
}

function MissionDetailsTitle(props) {
    return (
        <div className="mission-details-header-title">
            <h4>{props.title}</h4>
        </div>
    )
}

function MissionDetailsIconRow(props) {
    return (
        <div className="mission-details-icon-row d-flex flex-row flex-wrap column-gap-3 row-gap-1">
            {props.children}
        </div>
    )
}

function MissionDetailsIconComponent(props) {
    return (
        <div className="mission-details-icon-component d-flex flex-row column-gap-2">
            <MissionDetailsIcon icon={props.icon} />
            <MissionDetailsIconText text={props.text} />
        </div>
    )
}

function MissionDetailsIcon(props) {
    return (
        <div className="mission-details-icon">
            <img src={props.icon} />
        </div>
    )
}

function MissionDetailsIconText(props) {
    return (
        <div className="mission-details-icon-text body-3">
            {props.text}
        </div>
    )
}

function MissionDetailsHighlight(props) {
    return (
        <div className="mission-details-highlight body-1 p-3">
            {props.children}
        </div>
    )
}

function MissionDetailsConnectAndVerifyTextAndTooltip(props) {
    const verifyTooltip = "Signing your wallet verifies that you own that address. It prevents other users from impersonating you and allows us to verify that you meet the requirements for this mission."

    return (
        <div className="please-verify-section d-inline-flex flex-row align-items-center justify-content-start column-gap-2">
            <div className="body-1">
                Please connect and verify your wallet to continue.
            </div>
            <img src={TooltipIcon} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={verifyTooltip} />
        </div>
    )
}

function VertSpacer(props) {
    return (
        <div className='row vert-spacer-24' />
    )
}

export { MissionDetailsIconRow, MissionDetailsIconComponent, MissionDetailsHeader, MissionDetailsTitle, MissionDetailsHighlight, MissionDetailsConnectAndVerifyTextAndTooltip, VertSpacer }