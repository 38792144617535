import React, { useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../../user_portal/UserContext";




function PageNotFound({
    userDetails,
}) {
    const navigate = useNavigate()
    const { user, setUser, refreshUser } = useContext(UserContext);


    useEffect(() => {
        if (Object.keys(user).length === 0) {
            navigate("/signin")
        } else {
            navigate(user.auth_redirect)
        }
    }, []);

    return <></>
};

export default PageNotFound;
