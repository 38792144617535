import React, { useContext } from "react";
import { UserContext } from "../../user_portal/UserContext";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import LoadingIcon from "../../user_portal/public_portal/standard_components/LoadingIcon";

// Api Calls
import sessionAPI from "../../user_portal/preauth_portal/api/api_calls/sessionAPI";

// Expected - that user.authStatus is set to "user" and user.onboarding_status is set to "complete"
function ProtectedRoute({
  children
}) {
  return (
    <AuthRoute expectedUserStatus="user" expectedOnboardingStatus="complete" >
      {children ? children : <Outlet />}
    </AuthRoute>
  )
};

function PreAuthRoute({
  children
}) {
  return (
    <AuthRoute expectedUserStatus="" expectedOnboardingStatus="" >
      {children ? children : <Outlet />}
    </AuthRoute>
  )

};

// Not used today. 
function UnconfirmedUserRoute({
  children
}) {
  return (
    <AuthRoute expectedUserStatus="user" expectedOnboardingStatus="unconfirmed" >
      {children ? children : <Outlet />}
    </AuthRoute>
  )
};

function ConfirmedNoProfileRoute({
  children
}) {
  return (
    <AuthRoute expectedUserStatus="user" expectedOnboardingStatus="incomplete" >
      {children ? children : <Outlet />}
    </AuthRoute>
  )
};

// Public route - auth doesn't actually matter. 
// Hypothetically, a user could be logged in and connected and go here. 
// That should be fine. 
function PublicRoute({
  children
}) {
  return children ? children : <Outlet />;
};


// -----------------------------------------------
// -----------------------------------------------
// -----------------------------------------------

function AuthRoute({
  expectedUserStatus,
  expectedOnboardingStatus
}) {
  const { user, setUser, refreshUser } = useContext(UserContext);
  const navigate = useNavigate()

  if (Object.keys(user).length === 0) {
    return <LoadingIcon />
  } else {
    if (user.auth_status == expectedUserStatus && user.onboarding_status == expectedOnboardingStatus) {
      return <Outlet />;
    } else {
      return <Navigate to={user.auth_redirect} />
    }
  }
};


// -----------------------------------------------
// -----------------------------------------------
// -----------------------------------------------
// -----------------------------------------------
// -------------- HELPER FUNCTIONS----------------
// -----------------------------------------------
// -----------------------------------------------
// -----------------------------------------------
// -----------------------------------------------


// Check the user status against the expected status.
// Call check user api call.
// Compare current status to expected status.
// If there is no user, redirect to redirectPath.
// async function CheckUser(expectedStatus, userDetails, navigate) {
//   const response = await sessionAPI.checkUser(navigate)
//   const data = response.data

//   let currentStatus = ""
//   let redirectPath = ""
//   if (response.success) {
//     // There is a user! We were wrong. Just Set the User and carry on. 
//     const userEmail = data.email
//     currentStatus = data.onboarding_status
//     redirectPath = data.onboarding_redirect

//     userDetails.setUser(userEmail)
//     userDetails.setUserStatus(currentStatus)
//     localStorage.setItem("user", JSON.stringify(userEmail));
//     localStorage.setItem("userStatus", JSON.stringify(currentStatus));

//   } else {
//     // There is no user. 
//     currentStatus = ""
//     redirectPath = "/signin"

//     userDetails.setUser("")
//     userDetails.setUserStatus("")

//     localStorage.removeItem("user");
//     localStorage.removeItem("userStatus");
//   }

//   // Check if correct status. If not, redirect.
//   if (currentStatus == expectedStatus) {
//     // All is good.
//     return
//   } else {
//     // Not good. Redirect.
//     navigate(redirectPath)
//     return
//   }

// }

// -----------------------------------------------
// -----------------------------------------------
// --------- ADMIN HELPER FUNCTIONS --------------
// -----------------------------------------------
// -----------------------------------------------


function AdminRoute({
  userDetails,
  children
}) {
  AdminAuthRoute({ userDetails: userDetails, children: children })
  return children ? children : <Outlet />;
};


function AdminAuthRoute({
  userDetails,
  children
}) {
  // Always check for admin
  const navigate = useNavigate()
  CheckAdmin(userDetails, navigate)
};



async function CheckAdmin(userDetails, navigate) {
  const { user, setUser, refreshUser } = useContext(UserContext);

  const response = await sessionAPI.checkUser(navigate)

  const redirectPath = "/signin"
  let admin = false



  if (response.success) {
    // There is a user! We were wrong. Just Set the User and carry on. 
    admin = response.data.admin

  } else {
    if (Object.keys(user).length === 0) {
      return <LoadingIcon />
    } else {
      refreshUser();
    }

    // Check if correct status. If not, redirect.
    if (admin) {
      // All is good.
      return true
    } else {
      // Not good. Redirect.
      navigate(redirectPath)
      return false
    }
  }

}



export { ProtectedRoute, PreAuthRoute, UnconfirmedUserRoute, ConfirmedNoProfileRoute, PublicRoute, AdminRoute };
