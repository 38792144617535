import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';

import { MissionDetailsHighlight, VertSpacer } from "../../overview_components/CommonOverviewComponents";
import { FormField, PrimaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";
import IsValidEVMAddress from "../../../../utility_components/IsValidAddress";

// API Calls
import CashOutAPI from "../../api/api_calls/CashOutAPI";


function PublicCashOut(props) {
    const [blockchain, setBlockchain] = useState("Polygon");
    const [walletAddress, setWalletAddress] = useState("");
    const [confirmAddress, setConfirmAddress] = useState("");

    let params = useParams()
    const missionId = params.missionId;
    const navigate = useNavigate();
    const alert = useAlert();






    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------



    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    //Update state on input change
    function onChange(event) {
        switch (event.target.name) {
            case 'address':
                setWalletAddress(event.target.value)
            case 'confirmAddress':
                setConfirmAddress(event.target.value)
        }
    }

    function onSubmit(event) {
        event.preventDefault();

        // Make sure eth address matches confirmed eth address
        if (walletAddress != confirmAddress) {
            alert.show("Wallet addresses do not match", { type: 'error' })
            return;
        }

        // Make sure eth address is valid
        if (IsValidEVMAddress(walletAddress) == false) {
            alert.show("Not a valid " + blockchain + " address",
                { type: 'error' })
            return;
        }

        async function CashOutRequest() {
            const returnData = await CashOutAPI.createCashOut(missionId, walletAddress, blockchain, navigate)

            if (returnData.success) {
                props.setRefreshCashOuts(props.refreshCashOuts + 1)
                alert.show("Thank you for participating! Your reward will be sent within 48 hours", { type: "success" })
            } else {
                alert.show(returnData.error.message, { type: 'error' })
            }
        }

        CashOutRequest();
    }



    return (
        <>
            <CashOutForm onChange={onChange} blockchain={blockchain} onSubmit={onSubmit} />
        </>
    )

}

function CashOutForm(props) {

    return (
        <form onSubmit={(event) => props.onSubmit(event)} className="form row d-flex row-gap-4 ">
            <div className="body-1">
                What wallet should we send the reward to? This will be sent on {props.blockchain} within 48 hours.
            </div>
            <div className="gray-line" />
            <div className="col-12 mw-600 row-gap-4">
                <FormField label={props.blockchain + " wallet address (where we will send USDC)"} name="address" id="walletAddress" placeholder="EVM Wallet Address" type="text" onChange={props.onChange} marginClass="mb-4" />
                <FormField label="Confirm Wallet Address" name="confirmAddress" id="confirmAddress" placeholder="Confirm Address" type="text" onChange={props.onChange} marginClass="mb-4" />
                <PrimaryButtonSmall text="Submit Address" buttonType="submit" marginClass="" />
            </div>

        </form>
    )
}


export default PublicCashOut;