import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from 'react-alert';
import Placeholder from "../menu_components/Placeholder";
import { UserContext } from "../../../UserContext";
import CenteredLoadingIcon from "../CenteredLoadingIcon"

import InfoIcon from "../../../../../../assets/images/user_app/info_icon_gray.png"
import UserWalletAPI from "../../api/api_calls/UserWalletAPI";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useSignMessage, useAccount } from "wagmi";
import { recoverMessageAddress } from 'viem'
import { PrimaryButton } from "../../../preauth_portal/components/CommonComponents";



function WalletsCard(props) {
    const navigate = useNavigate();
    const alert = useAlert()
    const { user, setUser, refreshUser } = useContext(UserContext);

    const { address } = useAccount()
    const [messageToSign, setMessageToSign] = useState("Despark is having a server error. Signing this is harmless, but won't log you in. Please try again later.");
    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();

    const walletExists = address && props.wallets.length > 0 && props.wallets.find(wallet => wallet.address.toLowerCase() == address.toLowerCase())
    const walletConnectedAndVerified = walletExists && walletExists.signature_verified // If the wallet exists and is verified
    const walletUnverified = walletExists && !walletConnectedAndVerified // If the wallet exists but isn't verified


    const unconnectedInfoText = "Connect and verify your wallets to quality for missions"
    const connectedNotVerifiedInfoText = "Sign your wallet to verify it, or disconnect and add a new one to qualify for more missions"
    const connectedAndVerifiedInfoText = "Disconnect and add a new wallet to qualify for more missions"

    const connectInfoText = walletUnverified ? connectedNotVerifiedInfoText : walletConnectedAndVerified ? connectedAndVerifiedInfoText : unconnectedInfoText

    // --------------------------------------------
    // --------------------------------------------
    // --------------UseEffects------------------
    // --------------------------------------------
    // --------------------------------------------

    useEffect(() => {
        // console.log("Address: ", address)
        // console.log("Wallet exists: ", walletExists)
        // console.log("Unverified (New): ", walletUnverified)
        // console.log("Connected and Verified (New): ", walletConnectedAndVerified)


        const AddWallet = async (address) => {
            console.log("Adding wallet")
            const data = await UserWalletAPI.create({ address: address, handleRedirect: navigate })

            if (data["success"]) {
                props.updateWallets()
                alert.success("Wallet added")
            } else {
                alert.error("Unable to add wallet. Try again later or contact us at info@despark.io")
            }
        }

        const GetNonceAndPrepareSignature = async (address) => {
            console.log("Getting nonce")
            const wallet = address && props.wallets.find(wallet => wallet.address.toLowerCase() == address.toLowerCase())
            const result = await UserWalletAPI.getNonce({ walletId: wallet.id, navigate });

            if (result.success) {
                // Update the message to have the correct nonce.
                setMessageToSign(result.signature_message)
            } else {
                // Display an error message
                alert.error("Something went wrong - our servers can't currently handle wallet signatures. Please try again later or sign up using email. ")
            }
        }

        // Okay, so we're connected.
        // Things to do:
        // 1. If !address, do nothing. 
        // 2. If address exists (!!address), check if it's in our wallets list and whether it's verified. 

        // Once we're loaded, check if the wallet is in our list of wallets.
        if (!!address && props.loaded) {
            // Check if the wallet is in our list of wallets
            if (!walletExists) {
                // Wallet doesn't exist. Add it to our list of wallets for this user.
                AddWallet(address)
            } else if (walletUnverified) {
                /// Wallet exists but isn't verified. Prompt a signature.
                GetNonceAndPrepareSignature(address)
            } else {
                // Wallet exists and is verified. Do nothing.
            }
        }
    }, [address, props.loaded, props.wallets]);

    // Upon successful signature, verify the signature.
    useEffect(() => {
        // They've successfully signed.
        if (isSuccess) {
            const VerifySignature = async () => {
                console.log("Verifying signature")
                const wallet = address && props.wallets.find(wallet => wallet.address.toLowerCase() == address.toLowerCase())
                const result = await UserWalletAPI.verify({ walletId: wallet.id, address: address, signature_data: data, handleRedirect: navigate })

                if (result.success) {
                    // Update the wallets.
                    props.updateWallets()

                    alert.success("Wallet verified")
                } else {
                    // Display an error message
                    alert.error("Something went wrong. Please try again later. ")
                }
            }

            VerifySignature()
        }
    }, [isSuccess]);

    // Reset the sign in message if the address changess
    useEffect(() => {
        if (!address) {
            setMessageToSign("Despark is having a server error. Signing this is harmless, but won't log you in. Please try again later.")
        }
    }, [address])


    function PromptSignMessage() {
        if (messageToSign.startsWith("Logging")) {
            signMessage({ message: messageToSign });
        }
    }



    // --------------------------------------------
    // --------------------------------------------
    // --------------Helper Functions------------------
    // --------------------------------------------
    // --------------------------------------------

    function ConnectButtonRow() {

        return (
            <div className="connect-button-and-sign-button">
                <div className="do-not-shrink">
                    <ConnectButton label="Connect Wallet" showBalance={false} chainStatus="none" />
                </div>
                {walletExists && walletUnverified ?
                    <PrimaryButton text="Sign & Verify" onClick={() => PromptSignMessage()} />
                    :
                    walletConnectedAndVerified ?
                        <div className="verified-text">
                            Verified
                        </div>
                        :
                        null
                }
            </div>
        )
    }




    return (
        <div className="card rounded-body half-screen-height no-outline bg-secondary-color  text-white my-3" >
            <div className="card-title no-outline px-1 py-1">
                <h3 className="card-header">
                    My Wallets
                </h3>
            </div>
            <div className="card-body rounded-body bg-secondary-color text-white px-3 py-1">
                {!props.loaded ? <CenteredLoadingIcon /> :
                    <div className="wallets-content">
                        <div className="add-wallets-content">
                            <div className="new-card-subtitle">
                                Connect Wallet
                            </div>
                            <div className="connect-button-and-info-content">
                                <ConnectButtonRow />
                                <InfoCallOut text={connectInfoText} />
                            </div>
                        </div>
                        <div className="all-wallets-content">
                            <div className="new-card-subtitle">
                                All Wallets
                            </div>
                            <div className="wallet-table-and-info">
                                <AllWalletsTable wallets={props.wallets} updateWallets={props.updateWallets} />
                                <InfoCallOut text="Only verified wallets are used to determine if you meet the requirements for a mission" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}

function InfoCallOut(props) {
    return (
        <div className="info-call-out-container">
            <img src={InfoIcon} />
            <div className="info-call-out-text">
                {props.text}
            </div>
        </div>
    )
}

function AllWalletsTable(props) {
    const alert = useAlert()
    const navigate = useNavigate();

    function shortAddress(address) {
        if (address.length > 0) {
            const first = address.substring(0, 6)
            const last = address.substring(address.length - 4, address.length)
            return first + "..." + last
        }
    }

    function ForgetWallet(wallet) {

        const DeleteWallet = async () => {
            const data = await UserWalletAPI.destroy({ walletId: wallet.id, handleRedirect: navigate })

            if (data.success) {
                props.updateWallets()
                alert.success("Wallet deleted")
            } else {
                alert.error("Unable to delete wallet. Try again later or contact info@despark.io")
            }
        }

        DeleteWallet()
    }

    return (
        <>
            {
                props.wallets.length == 0 ?
                    <div className="no-wallets-table-note">
                        You have not connected any wallets
                    </div>
                    :
                    <div className="table-wrapper table-responsive rounded-corners">
                        <table className="table table-sm table-clean-no-background gray-text">
                            <thead>
                                <tr>
                                    <th> Wallet </th>
                                    <th> Verified </th>
                                    <th> Forget Wallet </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.wallets.map((wallet, index) => {
                                        return (
                                            <tr key={index} className="">
                                                <td> {shortAddress(wallet.address)} </td>
                                                <td>
                                                    {wallet.signature_verified ?
                                                        <div className="yes-table-text">Yes</div>
                                                        :
                                                        <div className="no-table-text">No</div>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="forget-wallet-tertiary" onClick={() => ForgetWallet(wallet)}>
                                                        Forget
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </>
    )
}



export default WalletsCard;

