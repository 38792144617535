import React, { useState, useEffect } from "react";
import { HashRouter, BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute, PreAuthRoute, UnconfirmedUserRoute, ConfirmedNoProfileRoute, PublicRoute, AdminRoute } from "../utility_components/routing_components/auth_routes";
import { createContext, useContext } from 'react';
import { UserContext } from "./UserContext";
import { useAlert } from 'react-alert';

import Dashboard from "./main_portal/pages/Dashboard";
import ExtendedProfile from "./main_portal/pages/ExtendedProfile";
import ExploreMissions from "./main_portal/pages/ExploreMissions";
import CashOutPage from "./main_portal/pages/CashOutPage";
import Wallets from "./main_portal/pages/Wallets"
import HeaderUser from "./main_portal/components/menu_components/HeaderUser";
import HeaderBasicNew from "./main_portal/components/menu_components/HeaderBasicNew";
import HeaderOnboarding from "./main_portal/components/menu_components/HeaderOnboarding";
import ContentOutline from "./main_portal/pages/ContentOutline";

import HeaderPublic from "./public_portal/standard_components/HeaderPublic";
import PublicMission from "./public_portal/PublicMission";

import SignUpQuestions from "./onboarding_portal/SignUpQuestions";

import JoinMeetingContent from "./meetings/JoinMeetingContent";

import SignIn from "./preauth_portal/pages/SignIn";
import SignInWallet from "./preauth_portal/pages/SignInWallet";
import SignUp from "./preauth_portal/pages/SignUp";
import SignUpWallet from "./preauth_portal/pages/SignUpWallet";
import SignUpPostWalletCredentialCollection from "./preauth_portal/pages/SignUpPostWalletCredentialCollection";
import ForgotPassword from "./preauth_portal/pages/ForgotPassword";
import ResetPassword from "./preauth_portal/pages/ResetPassword";
import EmailValidation from "./preauth_portal/pages/EmailValidation"

import PageNotFound from "../utility_components/routing_components/PageNotFound";
import PostSignUp from "../utility_components/routing_components/PostSignUp";


// import AdminSurvey from "../admin_components/AdminSurvey";

// import ExplorationPage from "../components/ExplorationPage";
// import ExplorationContent from "../components/exploration_components/ExplorationContent";

// API Calls
import sessionAPI from "./preauth_portal/api/api_calls/sessionAPI";


function App(props) {
    const [userLoaded, setUserLoaded] = useState(false);
    const [user, setUser] = useState({});
    const tempFunction = () => { console.log("temp function") }
    const [needUserRefresh, setNeedUserRefresh] = useState(true);

    function refreshUser() {
        setUser({});
        setNeedUserRefresh(true);
    }

    const userContextData = { user, setUser, refreshUser }

    const alert = useAlert();



    // # This probably changes at some point. Maybe along the lines of 
    useEffect(() => {
        // App loaded.
        // Grab initial information on the user.
        async function getUserInfo() {
            const response = await sessionAPI.userInfo();

            if (response.success) {
                setUser(response);
            } else {
                alert.show("Unable to load user info. Please try again later", { type: 'error' })
            }
        }

        if (needUserRefresh) {
            getUserInfo();
            setNeedUserRefresh(false);
        }
    }, [needUserRefresh]);




    return (
        <UserContext.Provider value={userContextData}>
            <BrowserRouter basename="/app">
                <Routes>
                    {/* These are the pre-auth routes - only available when logged out */}
                    <Route element={<PreAuthRoute />} >
                        <Route path='/signin' element={<><HeaderBasicNew /><SignIn /></>} />
                        <Route path='/signin/wallet' element={<><HeaderBasicNew /><SignInWallet /></>} />
                        <Route path='/forgotpassword' element={<><HeaderBasicNew /><ForgotPassword /></>} /> {/* This is the requesting page */}
                        <Route path='/resetpassword' element={<><HeaderBasicNew /><ResetPassword /></>} /> {/* This is the setting page (post-email) */}
                        <Route path='/signup' element={<><HeaderBasicNew logoRoute="homepage" /><SignUp /></>} /> {/* This sign up page */}
                        <Route path='/signup/wallet' element={<><HeaderBasicNew logoRoute="homepage" /><SignUpWallet /></>} /> {/* This sign up page */}
                        <Route path='/signup/walletcommunication' element={<><HeaderBasicNew logoRoute="homepage" /><SignUpPostWalletCredentialCollection /></>} />
                        <Route path='/postsignup/' element={<PostSignUp />} />
                        <Route path='/signup/emailverification' element={<><HeaderBasicNew /><EmailValidation /></>} />
                    </Route>
                    {/* If a user logs in without being confirmed, we redirect them here */}
                    {/* Not currently used. */}
                    {/* <Route element={<UnconfirmedUserRoute  />} >
            </Route> */}
                    {/* If a user logs in without completing profile, we redirect them here */}
                    <Route element={<ConfirmedNoProfileRoute />} >
                        <Route path='/onboarding' element={<><HeaderOnboarding /><SignUpQuestions /></>} /> {/* Sign Up Questions */}
                    </Route>

                    {/* This is the route for joining scheduled events. Auth is optional.  */}
                    <Route path='/meetings/:meetingSlug' element={<><HeaderBasicNew /><JoinMeetingContent /></>} />



                    {/* These are the protected routes - requiring authorization */}
                    <Route element={<ProtectedRoute />} >
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='/profile' element={<ExtendedProfile />} />
                        <Route path='/wallets' element={<Wallets />} />
                        <Route path='/missions' element={<ExploreMissions />} />
                        <Route path='/cashouts' element={<CashOutPage />} />

                        {/* The mission route */}
                        <Route path="/mission/"  >
                            <Route path=':missionId/*' element={<ContentOutline title="Mission" />} />
                        </Route>
                    </Route>

                    {/* This is the public route - you can access where you are logged in or not */}
                    {/* Will eventually be where community missions go.  */}
                    <Route element={<PublicRoute />} >
                        <Route element={<HeaderPublic />} >
                            <Route path='/public/missions/' >
                                <Route path=':missionId/*' element={<PublicMission />} />
                                {/* <Route index element={<PublicMissionOverview />} /> */}
                            </Route>
                        </Route>
                    </Route>

                    {/* <Route element={<AdminRoute />} >
                        <Route element={<HeaderUser />} >
                            <Route path='/admin/surveys/:surveyId' element={<AdminSurvey />} />
                        </Route>
                    </Route> */}


                    {/* This is the default route - if no other route is matched, this will be rendered */}
                    {/* For now, we redirect */}
                    <Route path="*" element={<PageNotFound />} />

                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    )
}


export default App;

// Routes also handles the UserHeader right now. 

// This imports React and the route files at ../routes/Index.jsx. 
// You then exported a component that renders the routes within fragments. 
// https://reactjs.org/docs/fragments.html
// The empty tags declare it as a fragment
// This component will be rendered at the entry point of the application, 
// thereby making the routes available whenever the application is loaded
