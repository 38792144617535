import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SecondaryButton, SecondaryButtonSmall, OrSeparater, LogInCard, LogInCardTitle, ErrorMessage, NoticeMessage } from "../components/CommonComponents"

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from "wagmi";
import LockIcon from '../../../../../assets/images/lock_icon.svg';

// Api Calls
import RegistrationAPI from "../api/api_calls/RegistrationAPI";


function SignUpWallet(props) {

  const [error, setError] = useState([]);
  const [notice, setNotice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [isConnected, setIsConnected] = useState(false);
  const { address } = useAccount();

  useEffect(() => {
    setIsConnected(!!address);
    setError("");

    // Okay, so we're connected.
    // Things to do:
    // 1. Check if the wallet is already registered
    // 2. If so, then we redirect them to the login page with a notice. 
    // 3. If not, then we direct them to the email and password signup page
    // 4. If they're not connected, then we keep showing the normal page here.

    if (!!address) {

      const CheckWalletExists = async () => {
        const result = await RegistrationAPI.checkWalletExists(address, navigate);

        if (result.success && !result.exists) {
          // Wallet does not exist.
          // Navigate them to the email and password signup page
          navigate("/signup/walletcommunication")
        } else if (result.success && result.exists) {
          // Navigate them to the login page with a notice
          navigate("/signin", { state: { notice: "This wallet already exists. Please login." } });
        } else {
          // Display an error message
          setError("Something went wrong. Please try again later. ")
        }
      }

      CheckWalletExists()
    }

  }, [address]);


  useEffect(() => {
    if (location.state) {
      const tempNotice = location.state.notice || "";
      setNotice(tempNotice)
    }
  }, []);



  return (
    <div className="container-fluid bg-black-color">
      <div className='row d-flex justify-content-center align-items-center h-100'>
        <ErrorMessage errors={error} />
        <NoticeMessage notices={notice} />
        <div className="bg-black-color full-page-background">
        </div>
        <div className="col12">
          <div className=' mw-440 px-1 my-80px mx-auto'>  {/* This sets the width and margins */}
            <LogInCard>
              <LogInCardTitle title="Create Account" />
              <div className="mt-2"></div>
              <div className="mt-5 mb-32 mx-2 ">
                <img src={LockIcon} />
              </div>
              <div className="card-body-2 px-2">
                <div className="d-flex flex-column align-items-center justify-content-center my-32">
                  <div className="terms-agreement text-center mb-2">
                    By signing up, I agree to the Despark <a href="https://despark.io/legal/respondent-terms-of-service/" target="_blank">Respondent Terms of Service</a> and <a href="https://despark.io/legal/privacy-policy/" target="_blank">Privacy Policy</a>.
                  </div>
                  <ConnectButton label="Connect Wallet" showBalance={false} chainStatus="none" />
                </div>
                <div className="bg-transparent-color">
                  <OrSeparater />
                  <div className="d-flex justify-content-center mt-32">
                    <SecondaryButton text="Sign up with email" buttonType="submit" onClick={() => navigate("/signup")} />
                  </div>
                </div>
              </div>
            </LogInCard>
            <div className="flex-column align-items-center pcx-30 my-32px w-100">
              <div className="sign-up-tertiary">Already have an account? <Link className="secondary-link" to={{ pathname: "/signin" }}>Log In</Link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}



export default SignUpWallet